import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout pathEn="/en/">
    <SEO title="Message envoyé - Dr Philippe Roure" />
    <h1>Message envoyé</h1>
    <p>
      Votre mesage a bien été envoyé. Nous vous répondrons dans les plus brefs
      délais
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </Layout>
)

export default NotFoundPage
